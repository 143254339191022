import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

class PhoneNumberParser {
	constructor(form, phoneInputId) {
		this.form = form;
		this.countrySelect = document.querySelector('select[name="country"]');
		this.phoneInput = document.getElementById(phoneInputId);
		this.phoneUtil = PhoneNumberUtil.getInstance();
		this.numberFormat = PhoneNumberFormat.E164;
		this.validationResult = PhoneNumberUtil.ValidationResult;
		this.supportedLanguages = ['cs', 'en', 'de'];
		this.translations = {
			cs: {
				invalidPhoneNumber: 'Neplatné telefonní číslo',
				missingCountryCode:
					'Telefonní číslo musí obsahovat regionální předvolbu.',
				numberTooShort: 'Telefonní číslo je příliš krátké.',
				numberTooLong: 'Telefonní číslo je příliš dlouhé.',
				numberInvalidLength: 'Neplatná délka telefonního čísla.',
			},
			en: {
				invalidPhoneNumber: 'Invalid phone number',
				missingCountryCode: 'Phone number must include country code.',
				numberTooShort: 'Phone number is too short.',
				numberTooLong: 'Phone number is too long.',
				numberInvalidLength: 'Invalid phone number length.',
			},
			de: {
				invalidPhoneNumber: 'Ungültige Telefonnummer',
				missingCountryCode:
					'Telefonnummer muss Ländervorwahl enthalten.',
				numberTooShort: 'Telefonnummer ist zu kurz.',
				numberTooLong: 'Telefonnummer ist zu lang.',
				numberInvalidLength: 'Ungültige Telefonnummerlänge.',
			},
		};
		this.language = this.getLanguage();
	}

	getLanguage() {
		const lang = document.documentElement.lang || 'en';
		return this.supportedLanguages.includes(lang) ? lang : 'en';
	}

	translate(key) {
		return this.translations[this.language][key] || key;
	}

	handleCheck(event) {
		if (event) {
			event.preventDefault();
		}

		// Zkontrolujeme a odstraníme předchozí chybovou zprávu
		this.clearError();

		let phoneNumber = this.phoneInput.value;

		if (!phoneNumber.startsWith('+')) {
			this.displayError(this.translate('missingCountryCode'));
			return false;
		}

		try {
			const number = this.phoneUtil.parseAndKeepRawInput(phoneNumber);
			const regionCode = this.phoneUtil.getRegionCodeForNumber(number);
			const numberToFormat = this.phoneUtil.parseAndKeepRawInput(
				phoneNumber,
				regionCode,
			);

			console.log(regionCode);

			// Nastavení selectu country na základě regionCode
			this.setCountrySelect(regionCode);

			let isPossible = this.phoneUtil.isPossibleNumber(numberToFormat);
			let isPossibleReason =
				this.phoneUtil.isPossibleNumberWithReason(numberToFormat);

			if (
				isPossible &&
				isPossibleReason === this.validationResult.IS_POSSIBLE &&
				this.phoneUtil.isValidNumber(numberToFormat)
			) {
				if (
					isPossibleReason ===
					this.validationResult.IS_POSSIBLE_LOCAL_ONLY
				) {
					this.displayError(this.translate('missingCountryCode'));
					return false;
				} else {
					this.phoneInput.value = this.phoneUtil.format(
						numberToFormat,
						this.numberFormat,
					);
					this.phoneInput.closest('div').classList.add('input-valid');
					return true; // Telefonní číslo je validní
				}
			} else {
				switch (isPossibleReason) {
				case this.validationResult.INVALID_COUNTRY_CODE:
					this.displayError(this.translate('missingCountryCode'));
					break;
				case this.validationResult.TOO_SHORT:
					this.displayError(this.translate('numberTooShort'));
					break;
				case this.validationResult.TOO_LONG:
					this.displayError(this.translate('numberTooLong'));
					break;
				case this.validationResult.INVALID_LENGTH:
					this.displayError(
						this.translate('numberInvalidLength'),
					);
					break;
				default:
					this.displayError(this.translate('invalidPhoneNumber'));
				}
				return false;
			}
		} catch (e) {
			this.displayError(this.translate('invalidPhoneNumber'));
			return false;
		}
	}

	clearError() {
		const errorMessage = this.phoneInput.nextElementSibling;
		if (errorMessage && errorMessage.classList.contains('error')) {
			errorMessage.remove();
		}
		this.phoneInput
			.closest('div')
			.classList.remove('input-error', 'input-valid');
	}

	displayError(message) {
		this.clearError(); // Vymažeme předchozí chybu, pokud existuje
		this.phoneInput.closest('div').classList.add('input-error');
		const errorMessage = document.createElement('span');
		errorMessage.classList.add('error');
		errorMessage.textContent = message;
		this.phoneInput.after(errorMessage);
	}

	// Funkce pro nastavení výběru země
	setCountrySelect(regionCode) {
		console.log(regionCode);
		let found = false;
		// Iterujeme přes všechny možnosti selectu country
		Array.from(this.countrySelect.options).forEach(option => {
			if (option.value === regionCode) {
				option.selected = true;
				found = true;
			}
		});

		// Pokud regionCode není nalezen, nastavíme výchozí hodnotu CZ
		if (!found) {
			this.countrySelect.value = 'CZ';
		}
	}

	handleSubmit(event) {
		const isValid = this.handleCheck(); // Zkontrolujeme číslo při odeslání
		console.log(isValid);
		if (!isValid) {
			event.preventDefault();
			alert('Telefonní číslo není platné'); // Zobrazíme alert, pokud číslo není platné
			return false; // Zabráníme odeslání, pokud je číslo neplatné
		}
	}
}

export default PhoneNumberParser;
