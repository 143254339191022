import naja from 'naja';
import YouTubePlayer from 'youtube-iframe-player';

class OEmbed {
	constructor() {}

	initialize(naja) {
		naja.addEventListener('success', () => this.make.bind());
		this.make();
	}

	make() {
		const elements = $('oembed');
		elements.each((i, el) => {
			const e = $(el);
			const youtubeId = this.youtubeParseUrl(e.attr('url'));
			if (youtubeId !== null) {
				e.attr('id', youtubeId);
				YouTubePlayer.init(() => {
					YouTubePlayer.createPlayer(youtubeId, {
						width: '720',
						height: '405',
						videoId: youtubeId,
						playerVars: { autoplay: 0, controls: 1 },
					});
				});
			}
		});
	}

	youtubeParseUrl(url) {
		let regExp =
			/^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
		let match = url.match(regExp);
		return match && match[2].length === 11 ? match[2] : null;
	}
}

naja.registerExtension(new OEmbed());
