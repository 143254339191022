import naja from 'naja';

if (!('IntersectionObserver' in window)) {
	require('intersection-observer-amd');
}

class MainMenu {
	constructor() {
		this.mainMenu = document.getElementById('masthead');
		this.mobileMenu = document.getElementById('mobile-main-menu');
		this.menuButton = document.getElementById('main-menu-button');
		this.prevScrollPos = this.getScrollPos();
		this.ccc = this.closeMobileMenuOutside.bind(this);
	}

	initialize(naja) {
		if (this.mainMenu) {
			this.menuShow();
		}
		if (this.menuButton && this.mainMenu && this.menuButton) {
			this.toggleOpen();
		}
	}

	menuShow() {
		let currentScrollPos = window.scrollY;
		if (currentScrollPos > 50) {
			this.mainMenu.classList.add('smaller');
		}
		window.addEventListener('scroll', () => {
			let currentScrollPos = this.getScrollPos();
			if (currentScrollPos > 50) {
				this.mainMenu.classList.add('smaller');
			} else {
				this.mainMenu.classList.remove('smaller');
			}
			this.prevScrollPos = currentScrollPos;
		});
	}

	getScrollPos() {
		return window.scrollY || document.body.scrollTop;
	}

	toggleOpen() {
		this.menuButton.addEventListener('click', event => {
			if (this.menuButton.classList.contains('active')) {
				this.menuButton.classList.remove('active');
				this.closeMobileMenuButton();
				document.documentElement.classList.remove('no-scroll');
			} else {
				this.menuButton.classList.add('active');
				this.openMobileMenu();
				document.documentElement.classList.add('no-scroll');
			}
		});
	}

	openMobileMenu() {
		this.mobileMenu.classList.add('active');
		this.mainMenu.classList.add('active-menu');
		//this.setMobileMenuTopOffset();
		setTimeout(() => {
			window.addEventListener('click', this.ccc);
		}, 300);
	}

	closeMobileMenuButton() {
		this.mobileMenu.classList.remove('active');
		this.menuButton.classList.remove('active');
		this.mainMenu.classList.remove('active-menu');
		document.documentElement.classList.remove('no-scroll');
		window.removeEventListener('click', this.ccc);
		//this.setMobileMenuTopOffset();
	}

	closeMobileMenuOutside(event) {
		if (event.target !== this.mobileMenu) {
			this.mobileMenu.classList.remove('active');
			this.menuButton.classList.remove('active');
			this.mainMenu.classList.remove('active-menu');
			document.documentElement.classList.remove('no-scroll');
			window.removeEventListener('click', this.ccc);
			//this.setMobileMenuTopOffset();
		}
	}

	setMobileMenuTopOffset() {
		if (this.prevScrollPos > 250) {
			this.mobileMenu.style.top = '60px';
		} else {
			this.mobileMenu.style.top = '120px';
		}
	}
}

naja.registerExtension(new MainMenu());
