import naja from 'naja';

if (!('IntersectionObserver' in window)) {
	require('intersection-observer-amd');
}
import LazyLoad from 'vanilla-lazyload';

class LazyLoadExtension {
	constructor() {}

	initialize(naja) {
		window.LazyLoad = new LazyLoad();
	}
}

naja.registerExtension(new LazyLoadExtension());
